.container__message__parent {
  z-index: 1101;
}

.container-message {
  border-radius: 10px;
  top: 30%;
  width: 22%;
  padding-bottom: 10px;
  height: 20%;
}
.group-items {
  display: flex;
  align-items: center;
}
.footer-buttons {
  border-top: none;
}
.message-warning {
  color: var(--color-warning);
}
.message-error {
  color: var(--color-rojo);
}
.message-success {
  color: var(--color-verde);
}
.icon-element {
  font-size: 7rem;
}
.text-items h1 {
  font-size: 2.4rem;
}
.text-items p {
  font-size: 1.4rem;
}

@media (max-width: 1500px) {
  .container-message {
    border-radius: 10px;
    top: 30%;
    width: 30%;
    padding-bottom: 10px;
    height: 20%;
  }
  .icon-element {
    font-size: 6rem;
  }
}
@media (max-width: 1080px) {
  .container-message {
    border-radius: 10px;
    top: 30%;
    width: 40%;
    padding-bottom: 10px;
    height: 20%;
  }
  .icon-element {
    font-size: 5rem;
  }
}
@media (max-width: 820px) {
  .container-message {
    border-radius: 10px;
    top: 30%;
    width: 45%;
    height: 20%;
  }
  .icon-element {
    font-size: 3.6rem;
  }
  .text-items h1 {
    font-size: 2rem;
  }
  .text-items p {
    font-size: 1.4rem;
  }
}
