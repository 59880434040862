.container-header {
  background-image: linear-gradient(to top, var(--color-menu), white);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
  height: 100px;
  position: fixed;
  z-index: 1100;
  max-width: 1910px;
  width: 100%;
  top: 0;
}

.container-menu-primary .nav-link.container-menu-primary--login,
.container-menu-primary .nav-link.container-menu-primary--login:focus {
  color: var(--color-link) !important;
  font-size: 1.2rem;
  padding-top: 15px;
  letter-spacing: 0.7px;
}

.container-menu-primary .nav-link.container-menu-primary--login:hover {
  color: var(--color-rojo) !important;
}

.container-menu-primary button.btn {
  font-size: 1.2rem;
}

.container-menu li.nav-item {
  font-size: 1.2rem;
  letter-spacing: 1.4px;
}

.container-menu li.nav-item a.nav-link {
  color: var(--color-text);
}

.navbar-brand img {
  position: absolute;
  margin-left: 100px;
  top: 25px;
  width: 220px;
  cursor: pointer;
}

.navbar {
  height: 100px;
}
.nav-item {
  font-size: 1.6rem;
}

.nav-item a {
  color: gray;
  margin-right: 15px;
}

.nav-item button {
  font-size: 1.4rem;
}

.nav-link:hover {
  color: var(--color-dorado) !important;
  text-decoration: underline;
}

.nav-link.active {
  color: var(--color-dorado) !important;
}
.buttons-auctions {
  font-size: 1.6rem;
  margin-top: 10px;
  margin-left: 50px;
}

@media (max-width: 1025px) {
  .navbar-brand img {
    margin-left: 35px;
    width: 200px;
  }

  .container-menu li.nav-item {
    font-size: 1rem;
    margin-top: 10px;
  }
}
@media (max-width: 931px) {
  .buttons-auctions {
    font-size: 1.4rem;
    margin-top: 10px;
    margin-left: 50px;
  }
}

@media (max-width: 768px) {
  .container-header {
    height: 85px;
  }
  .navbar-brand img {
    width: 150px;
  }
  .container-menu li.nav-item {
    font-size: 0.8rem;
  }
  .container-menu-primary .nav-link.container-menu-primary--login {
    font-size: 1rem;
  }
  .container-menu-primary button.btn {
    font-size: 1rem;
  }
}

@media (max-width: 620px) {
  .container-header {
    height: 80px;
  }
  .navbar-brand img {
    width: 150px;
  }
  .container-menu li.nav-item {
    font-size: 0.7rem;
  }
  .container-menu-primary .nav-link.container-menu-primary--login {
    font-size: 0.9rem;
  }
  .container-menu-primary button.btn {
    font-size: 0.9rem;
  }
}

@media (min-width: 576px) {
  .container__users--secondUser,
  .container__menu--secondLogin {
    display: none;
  }
}

@media (max-width: 575px) {
  .navbar-brand img {
    width: 200px;
    top: 15px;
  }
  .navbar-expand-sm {
    text-align: end;
    margin-right: 10px;
    margin-top: 10px;
  }
  button > i.fa-bars {
    font-size: 4rem;
  }

  .navbar-nav {
    text-align: center;
  }

  .container-menu {
    background-color: white;
    width: 100vw;
  }

  .container-menu li.nav-item {
    font-size: 1.1rem;
    border-bottom: 1px var(--color-gris) solid;
  }

  .container__menu--secondLogin-login {
    padding-top: 10px;
  }
  .container__menu--secondLogin-login,
  .container__menu--secondLogin-register {
    margin-top: 20px;
  }
  .container__menu--secondLogin a {
    color: var(--color-link);
    letter-spacing: 0.7px;
  }

  .container-menu-primary--login,
  .container__menu--secondLogin button {
    font-size: 1.3rem;
  }

  .container__users--secondUser {
    margin-bottom: 15px;
  }

  .modal-sm {
    width: 300px;
    margin: 0 auto;
  }
}
