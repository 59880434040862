.container-catalog {
  margin-top: 10%;
  width: 90%;
}

.title-sections {
  margin: 0px 12px 6px 0px;
  font-size: 3.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 6px;
  text-align: left;
  color: var(--color-text);
}

.gold-text {
  color: var(--color-dorado);
}

.time-auction {
  height: 22px;
  margin: 23px 65px 23px 0px;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.8px;
  text-align: left;
  color: var(--color-text);
}

.col-end-section {
  text-align: end;
  margin-right: 15px;
}

.col-input .input-element {
  font-size: 1.6rem;
  width: 100%;
  height: 39px;
  margin: 23px 0px 26px 0px;
  padding: 7px 15px 8px 23px;
  border-radius: 3px;
  border: solid 2px var(--color-azul);
}

.input-search {
  font-size: 1.6rem;
  width: 100%;
  height: 39px;
  margin: 23px 0px 26px 0px;
  padding: 7px 15px 8px 23px;
  border-radius: 3px;
  border: solid 2px var(--color-azul) !important;
}

.action-search {
  width: 24px;
  height: 24px;
  margin: 0 0 0 0;
  padding: 3px 3.5px 3.5px 3px;
}

.serch-placeholder {
  width: 189px;
  height: 22px;
  margin: 1px 0px 1px 0;
  font-size: 1.6rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: var(--color-gris);
}

.drop-element {
  width: 100%;
  height: 39px;
  margin: 23px 0 26px 0px;
  padding: 8px 15px 7px 26px;
  border-radius: 3px;
  border: solid 2px var(--color-azul);
  font-size: 1.6rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
}

.drop-element-2 {
  width: 100%;
  height: 39px;
  margin: 23px 0px 26px 0;
  padding: 8px 15px 7px 26px;
  font-size: 1.6rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  border-radius: 3px;
  border: solid 2px var(--color-azul);
}

.drop-items {
  height: 22px;
  font-size: 1.6rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: var(--color-azul);
}

.items-per-page {
  font-size: 1.4rem !important;
  color: var(--color-azul) !important;
  border-radius: 3px;
  font-weight: bold;
  font-size: 1rem;
}

.show-perpag {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--color-text);
}

.drop-perPage {
  margin-left: 20%;
  text-align: center;
  width: 80%;
  margin-top: -6px;
  border-radius: 3px;
  border: solid 2px var(--color-azul);
}

.prev-pag {
  width: 28px;
  height: 31px;
  margin: -7px 6px 25px 19px;
  padding: 6px 3px 0px 1px;
  border-radius: 3px;
  border: solid 2px var(--color-azul);
}

.num-pages {
  margin-top: -6px;
  font-size: 1.6rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: var(--color-text);
}

.page-active {
  background-color: var(--color-azul);
  background: var(--color-azul);
  color: var(--color-blanco);
}

.page-active:focus {
  background-color: var(--color-azul);
  background: var(--color-azul);
  color: var(--color-blanco);
}

.next-pag {
  width: 28px;
  font-size: 0.1rem;
  height: 31px;
  margin: -7px 6px 25px 19px;
  padding: 6px 3px 0px 1px;
  border-radius: 3px;
  border: solid 2px var(--color-azul);
}

.pagination-section {
  margin-top: 0;
}

.box-items {
  width: 30px;
  height: 28px;
  margin: -6px 0px 0px -2px;
  background-color: var(--color-blanco);
  color: var(--color-azul);
  font-weight: bold;
  border: 2px solid var(--color-azul);
  border-radius: 3px;
}

.box-items2:hover {
  background-color: var(--color-blanco);
  color: var(--color-azul);
}

.box-items2 {
  width: 30px;
  height: 28px;
  color: var(--color-blanco);
  margin: -6px 0px 0px -2px;
  font-weight: bold;
  border: 2px solid var(--color-azul);
  background-color: var(--color-azul);
  border-radius: 3px;
}

.card-products-items {
  margin-right: 0%;
}

.container-products-items--img {
  height: auto;
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.img-lot {
  width: 100%;
  height: auto;
}

.image-list {
  margin: 0 auto;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.img-lot-2 {
  padding: 10% 5px 5% 5px;
  width: 100%;
  height: auto;
}

.container-products-items--title {
  border-top: var(--color-dorado) 1px solid;
  padding-top: 13px;
  font-size: 1.6rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-text);
}

.product-text {
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 00.1rem;
}

.button-eye {
  border: 2px solid;
  font-size: 1.4rem;
}

.button-eye:hover img {
  filter: hue-rotate(255deg) brightness(500%);
}

.button-eye-2 {
  margin-right: 10px;
  border: 2px solid;
  font-size: 1.4rem;
}

.price-text {
  color: var(--color-rojo);
  letter-spacing: 00.1rem;
}

.article-reference {
  font-size: 1.8rem;
  margin-top: 15px;
  font-weight: bold;
}

.article-footer {
  margin-left: 30px;
  margin-right: 30px;
  border-top: none;
}

img.Star-4 {
  width: 28px;
  height: 28px;
  margin: 0 0 264px 41px;
  object-fit: contain;
  border-radius: 1px;
  border: solid 1px #b29332;
  background-color: #b29332;
}

.card-left {
  height: 90%;
  padding-left: 60px;
  margin-top: 2%;
  margin-bottom: 2%;
  border-left: 1px solid var(--color-dorado);
}

.text-bottom {
  margin-top: 15px;
  font-size: 1.6rem;
  letter-spacing: 00.1rem;
}

.modal-top {
  z-index: 0;
}

.modal-top img {
  max-width: 480px;
  max-height: 360px;
}

.grid-items {
  margin-top: 30px;
}

.text-desc {
  padding-top: 3px;
  margin: 0px 5px 0px 5px;
  border-top: 1px solid var(--color-dorado);
}

.modal-footer {
  border-top: none;
}

.button-reg {
  font-size: 1.6rem;
}

.drop-element-offer {
  width: 100%;
  font-size: 1.3rem;
  border-radius: 2px;
  border: solid 1px var(--color-azul);
}

.drop-element-offer-2 {
  font-size: 1.3rem;
  border-radius: 2px;
  border: solid 1px var(--color-azul);
}

.drop-items-2 {
  text-align: left;
  color: var(--color-azul);
}

.drop-scroll {
  max-height: 280px;
  overflow-y: scroll;
}

.align-buttons {
  width: 100%;
}

.align-buttons-items {
  display: inline-block;
}

.buttons-offer {
  z-index: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.timer-start {
  border-radius: 3px;
  background-color: var(--color-rojo);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 5px;
  color: var(--color-blanco);
  text-align: center;
}

.timer-course {
  border-radius: 3px;
  background-color: var(--color-azul);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  color: var(--color-blanco);
  text-align: center;
}

section p:first-child {
  font-size: 3rem;
}

section p small {
  text-decoration: none;
  font-size: 0.8rem;
}

.separator {
  font-size: 3rem;
}

.title-auction-start {
  color: var(--color-azul);
}

.title-auction-course {
  color: var(--color-rojo);
}

.header-closing {
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.success-offer-items {
  font-size: 1.4rem;
  width: 40%;
  margin-bottom: 50px;
}

.closing-accept {
  margin: 0px 50px 50px 50px;
  letter-spacing: 0.3rem;
}

.closing-accept span {
  color: var(--color-azul);
}

.button-danger-offerted {
  border-radius: 5px;
  background-color: var(--color-rojo);
  color: var(--color-blanco);
  box-shadow: none;
}

.button-danger-offerted:hover {
  box-shadow: none;
  background-color: var(--color-blanco);
  color: var(--color-rojo);
}

.pallet-section {
  text-align: right;
}

.ellipsis {
  margin-top: 6px;
}

.text-blue {
  color: var(--color-azul);
}

.clean-filters {
  cursor: pointer;
  font-weight: bold;
  text-shadow: 1px 1px var(--color-gris);
}

.filters-border {
  border-bottom: 3px solid var(--color-azul);
  margin-bottom: 14px;
  padding-bottom: 8px;
  width: 70%;
}
.input-check-cat{
  min-width: 18px;
}
.form-check-input {
  width: 18px;
  height: 18px;
}
.form-check-input[type='checkbox']:focus {
  padding: 3px;
}
.retired-bg {
  background-color: var(--color-gris);
}

.lot-retired {
  border: 6px solid var(--color-gris);
}

.lot-won {
  border: 6px solid var(--color-azul);
}

.won-bg {
  background-color: var(--color-azul);
}
.head-lots{
  margin: -3px;
}
.head-lots img {
  padding: 3px 0 3px 0;
}

.head-lots h4 {
  font-size: 1.5rem;
  padding-top: 4px;
}

.lot-sold {
  border: 6px solid var(--color-naranja);
}

.sold-bg {
  background-color: var(--color-naranja);
}

.lot-now {
  border: 6px solid var(--color-rojo);
}

.now-bg {
  background-color: var(--color-rojo);
}

.lot-next {
  border: 6px solid var(--color-award);
}

.next-bg {
  background-color: var(--color-award);
}

.filters h5 {
  font-size: 1.6rem;
  letter-spacing: 2px;
}

.filters h4 {
  font-size: 2rem;
  font-weight: bold;
  color: var(--color-azul);
  letter-spacing: 2px;
}

.filters span {
  color: var(--color-text);
  letter-spacing: 2px;
}

form.example input[type=text] {
  padding: 7px 22px 8px 35px;
  border-radius: 3px;
  border: solid 2px var(--color-text);
  border-right: none;
  float: left;
  font-size: 16px;
  width: 80%;
  height: 39px;
  margin-top: 24px;
}

form.example input[type=text]:focus-visible {
  outline: none;
}

form.example button {
  margin: 24px 0 0 -2px;
  float: left;
  width: 20%;
  height: 39px;
  border: solid 2px var(--color-text);
  color: var(--color-text);
  padding: 5px;
  background: var(--color-blanco);
  font-size: 17px;
  border-left: none;
  cursor: pointer;
}

form.example i:hover {
  transform: scale(1.4);
}

form.example::after {
  content: "";
  clear: both;
  display: table;
}

.arrows {
  width: auto;
}

.title-cats {
  font-size: 2.3rem;
  font-weight: bold;
  letter-spacing: 3px;
}

.title-cat-section {
  border-bottom: 2px solid var(--color-gris);
}

.title-cat-section:hover {
  cursor: pointer;
}

.filter-label {
  font-size: 1.4rem;
  font-weight: bold;
}

.scroll-cats {
  overflow-y: auto;
  max-height: 150px;
}

.form-check-input[type='checkbox']:checked {
  width: 19px;
  height: 19px;
  padding: 4px;
  background-color: var(--color-azul);
}

.form-range::-webkit-slider-thumb {
  background: var(--color-blanco);
  border: 1px solid var(--color-text);
  padding-top: 15px;
  padding-left: 12px;
}

.form-range::-moz-range-thumb {
  background: var(--color-blanco);
  border: 1px solid var(--color-text);
  padding-top: 15px;
  padding-left: 12px;
}

.form-range::-ms-thumb {
  background: var(--color-blanco);
  border: 1px solid var(--color-text);
  padding-top: 15px;
  padding-left: 12px;
}

.form-range::-webkit-slider-runnable-track {
  border-radius: 5px;
  background: var(--color-azul);
}

.form-range::-moz-range-runnable-track {
  border-radius: 5px;
  background: var(--color-azul);
}

.form-range::-ms-runnable-track {
  border-radius: 5px;
  background: var(--color-azul);
}

@media (max-width: 1396px) {
  .drop-element-offer {
    margin-right: 0px;
    width: 100%;
    font-size: 1.4rem;
    border-radius: 2px;
    border: solid 1px var(--color-azul);
  }

  .drop-element-offer-2 {
    font-size: 1.4rem;
    border-radius: 2px;
    margin-right: 40px;
    border: solid 1px var(--color-azul);
  }

  .drop-perPage {
    margin-left: -2px;
    width: 90%;
  }

  .show-perpag {
    margin-left: -21px;
  }

  .pagination-section {
    margin-left: 10px;
  }

  .img-lot-2 {
    padding: 15% 5px 5% 5px;
  }
}

@media (max-width: 1300px) {
  .container-catalog {
    margin-top: 12%;
  }

  .text-bottom {
    margin-top: 0px;
  }

  .article-reference {
    margin-top: 0px;
  }

  .product-text {
    margin-top: 0px;
  }

  .pallet-section {
    margin-top: 5px;
  }

  .drop-element {
    padding: 8px 4px 7px 10px;
  }

  .drop-element-2 {
    padding: 8px 4px 7px 10px;
  }

  .col-input .input-element {
    padding: 8px 4px 7px 10px;
  }

  form.example input[type=text] {
    padding: 7px 0px 8px 7px;
  }

  .title-cats {
    font-size: 2.1rem;
  }

  .filters h5 {
    font-size: 1.5rem;
  }

  .filters h4 {
    font-size: 1.9rem;
  }
}

@media (max-width: 1199px) {
  .won-bg img {
    padding: 4px 0 4px 14px;
  }

  .won-bg h4 {
    font-size: 1.4rem;
    padding-top: 4px;
  }
  .img-lot-2 {
    padding: 19% 5px 5% 5px;
  }
}

@media (max-width: 1146px) {
  .won-bg img {
    padding: 10px 0 10px 14px;
  }

  .won-bg h4 {
    font-size: 1.3rem;
  }
}

@media (max-width: 1102px) {
  .won-bg img {
    padding: 14px 0 14px 16px;
  }

  .won-bg h4 {
    font-size: 1.2rem;
  }
  .img-lot-2 {
    padding: 22% 5px 5% 5px;
  }
}

@media (max-width: 1025px) {
  .container-catalog {
    margin-top: 16%;
  }

  .drop-element {
    font-size: 1.1rem;
    padding: 8px 2px 7px 2px;
  }

  .drop-element-2 {
    font-size: 1.1rem;
    padding: 8px 2px 7px 2px;
  }

  .col-input .input-element {
    font-size: 1.1rem;
    padding: 8px 2px 7px 2px;
  }

  .items-per-page {
    padding: 6.5px 2px 5px 2px;
  }

  form.example input[type=text] {
    font-size: 1.2rem;
    padding: 7px 0px 8px 4px;
  }

  .filters h5 {
    font-size: 1.2rem;
  }

  .filters h4 {
    font-size: 1.6rem;
  }

  .title-cats {
    font-size: 1.6rem;
    letter-spacing: 1px;
  }
  .img-lot-2 {
    padding: 24% 5px 5% 5px;
  }
}

@media (max-width: 899px) {
  .img-lot-2 {
    padding: 40% 5px 5% 5px;
  }
}

@media (max-width: 766px) {
  .title-sections {
    font-size: 3.2rem;
  }

  .container-catalog {
    margin-top: 18%;
  }

  .time-auction {
    font-size: 1.6rem;
  }

  .align-buttons {
    display: table;
  }

  .align-buttons-items {
    display: table-cell;
    vertical-align: middle;
  }

  .drop-element {
    font-size: 1.6rem;
    padding: 8px 0px 7px 10px;
  }

  .drop-element-2 {
    font-size: 1.6rem;
    padding: 8px 0px 7px 10px;
  }

  .col-input .input-element {
    font-size: 1.6rem;
    padding: 8px 0px 7px 10px;
  }

  .pagination-section {
    margin-top: 20px;
  }

  .box-items {
    margin-top: 10px;
  }

  .box-items2 {
    margin-top: 10px;
  }

  .col-input .input-element {
    margin: 0px 0px 10px 0px;
  }

  .input-search {
    margin: 0px 0px 10px 0px;
  }

  .drop-element {
    margin: 10px 0px 0px 0px;
  }

  .drop-element-2 {
    margin: 10px 0px 10px 0;
  }

  .drop-per-page {
    width: 90%;
  }

  .img-lot-2 {
    padding: 10px;
  }

  form.example input[type=text] {
    font-size: 1.6rem;
    padding: 7px 22px 8px 35px;
  }

  .filters h5 {
    font-size: 1.6rem;
  }

  .filters h4 {
    font-size: 2rem;
    letter-spacing: 5px;
  }

  .title-cats {
    font-size: 2.4rem;
    letter-spacing: 5px;
  }
}

@media (max-width: 577px) {
  .box-items i {
    font-size: 1.4rem !important;
  }

  .box-items2 i {
    font-size: 1.4rem !important;
  }
}

@media (max-width: 500px) {
  .container-catalog {
    margin-top: 24%;
    width: 85%;
  }

  .title-sections {
    text-align: center;
  }

  .time-auction {
    font-size: 1.4rem;
    text-align: center;
  }

  .pallet-section {
    text-align: center;
  }

  .drop-perPage {
    margin-left: 5px;
    margin-right: 2px;
    width: 85%;
  }

  .next-pag {
    width: 26px;
    margin: -7px 0px 25px 5px;
    padding: 6px 0px 0px 1px;
  }

  .prev-pag {
    width: 26px;
    margin: -7px 3px 25px 0px;
    padding: 6px 0px 0px 0px;
  }

  .show-perpag {
    margin-left: 3px;
  }
}

@media (max-width: 375px) {
  .container-catalog {
    margin-top: 28%;
  }

  .title-sections {
    font-size: 2.8rem;
  }

  .time-auction {
    font-size: 1.2rem;
  }

  .drop-element {
    font-size: 1.2rem;
  }

  .drop-element-2 {
    font-size: 1.2rem;
  }

  .drop-items {
    font-size: 1.2rem;
  }

  .drop-items-2 {
    font-size: 1.2rem;
  }

  .col-input .input-element {
    font-size: 1.2rem;
  }
}