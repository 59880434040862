footer > div > section {
  background-color: #808080;
  color: white;
}

.container-footer-tel {
  font-size: 1.65rem;
}

.col-footer {
  margin-top: 20px;
}

.col-footer p > label,
.col-footer p > a {
  font-size: 1.1rem;
}

.lbl-direction {
  font-size: 1.3rem;
}

.col-footer p > span {
  font-size: 4rem;
  margin-left: 20px;
}

.col-footer a {
  color: white;
}

.container-footer-tel a:hover {
  border-bottom: 2px white solid;
  padding-bottom: 3px;
}

.container-footer2 {
  background-color: white;
  color: #4f4f4f;
  font-size: 1.3rem;
}

.container-footer2 > a {
  color: var(--color-turquesa) !important;
}

.container-footer2 > a:hover {
  color: #0e52c1 !important;
}
.hover-footer:hover {
  text-decoration: underline;
}
@media (max-width: 1400px) {
  .container-footer-tel {
    font-size: 1.6rem;
  }
}

@media (max-width: 1200px) {
  .container-footer__align {
    text-align: center;
  }
}
@media (max-width: 1025px) {
  .img-fluid,
  .img-thumbnail {
    max-width: 85%;
  }
  .h2,
  h2 {
    font-size: calc(1.325rem + 0.6vw);
  }
  .container-footer-tel {
    font-size: 1.5rem;
  }
  .col-footer p > label {
    font-size: 1.2rem;
  }
  .lbl-direction {
    font-size: 1.2rem;
  }
  .container-footer2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .img-logo-footer {
    width: 280px;
  }
  .container-footer-tel {
    font-size: 1.8rem;
  }
  .col-footer p > label {
    font-size: 1.9rem;
  }
  .col-footer p > label,
  .col-footer p > a {
    font-size: 1.3rem;
  }
  .col-footer p > span {
    font-size: 5rem;
  }
  .lbl-direction {
    font-size: 1.6rem;
  }
  .container-footer2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 575px) {
  .img-logo-footer {
    width: 200px;
  }
  .container-footer-tel {
    font-size: 1.85rem;
  }
  .col-footer p > label {
    font-size: 1.5rem;
  }
  .col-footer p > label,
  .col-footer p > a {
    font-size: 1.3rem;
  }
  .col-footer p > span {
    font-size: 4rem;
  }
  .lbl-direction {
    font-size: 1.2rem;
  }
  .container-footer2 {
    font-size: 1.1rem;
  }
}
