.container-services {
  width: 90%;
  margin-top: 10%;
  margin-bottom: 10%;
}
.title-services {
  height: 29px;
  margin: 0 29px 43px 0px;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 6px;
  text-align: left;
  color: var(--color-dorado);
}
.title-services > div > span {
  color: var(--color-text);
}
.consig {
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-azul);
}
.consig-section {
  font-size: 1.6rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: var(--color-text);
}
.consig-section > p > span {
  font-weight: 600;
}

.phone-number {
  color: var(--color-text);
  font-size: 2.2rem;
}
.phone-number:hover {
  color: var(--color-text);
  text-decoration: underline;
}
.email-contact {
  color: var(--color-turquesa);
  font-size: 1.5rem;
}

.container-services__align {
  text-align: right;
}
.desc{
  font-size: 2rem;
}
@media (max-width: 1250px) {
  .container-services {
    margin-top: 15%;
  }
  .phone-number {
    font-size: 1.9rem;
  }
  .desc{
    font-size: 1.6rem;
  }
  }
  
  @media (max-width: 1100px) {
    .phone-number {
      font-size: 1.8rem;
    }
    
}

@media (max-width: 1025px) {
  .container-services {
    margin-bottom: 10%;
  }

  .title-services,
  .consig,
  .phone-number {
    font-size: 1.6rem;
  }
  .consig-section,
  .email-contact {
    font-size: 1.3rem;
  }
  .img-fluid {
    max-width: 100%;
  }
}

@media(max-width: 992px) {

  .container-services__align,
  .container-services__alignDesc {
    text-align: center;
  }
  .container-services__align h2{
    margin: 0;
  }
  .container-services__alignDesc .desc{
    margin: 0 0 10px;
  }
}
@media (max-width: 890px) {
  .title-services,
  .consig,
  .phone-number {
    font-size: 1.8rem;
  }
  .title-services {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 769px) {
  .title-services,
  .consig,
  .phone-number {
    font-size: 1.2rem;
    height: 5px;
  }
  .consig-section,
  .email-contact {
    font-size: 1rem;
  }
  .container-services {
    margin-bottom: 10%;
    margin-top: 18%;
  }
}

@media (max-width: 575px) {
  .title-services {
    margin: 0;
    margin-bottom: 40px;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .container-services {
    margin-top: 25%;
  }
}

@media (max-width: 350px) {
  .container-services {
    margin-top: 30%;
  }
}

@media (min-width: 1780px) {
  .container-services {
    max-width: 1587px;
  }
}
