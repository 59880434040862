.container__recoverypwd {
  margin-top: 15%;
  margin-bottom: 7%;
}

.card .card-title {
  text-align: left;
  font-size: 1.4rem;
  letter-spacing: 0px;
  border-bottom: 1px lightgrey solid;
  padding-bottom: 10px;
}

.container__recoverypwd button {
  font-size: 1.1rem;
}

.fa-gem {
  margin-right: 10px;
  color: var(--color-rojo);
  font-weight: bold;
  font-size: 1.8rem;
}

.container__recoverypwd label {
  font-size: 1.2rem;
}

.container__recoverypwd.hidden {
  visibility: hidden;
}

@media (max-width: 993px) {
  .container__recoverypwd {
    margin-top: 18%;
  }
  .card .card-title {
    font-size: 1.2rem;
  }
  .fa-gem {
    font-size: 1.5rem;
  }
}

@media (max-width: 769px) {
  .container__recoverypwd {
    margin-top: 20%;
  }
  .card .card-title {
    font-size: 1rem;
  }
  .container__recoverypwd button {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .container__recoverypwd {
    margin-top: 25%;
  }
}

@media (max-width: 400px) {
  .container__recoverypwd {
    margin-top: 35%;
  }

  .container__recoverypwd .input-group.input-password > i {
    margin-left: 85% !important;
  }
}
