:root {
  --color-menu: #e9e9e9;
  --color-blanco: #ffffff;
  --color-link: #144861;
  --color-rojo: #9d1a27;
  --color-text: #4f4f4f;
  --color-azul: #3c5883;
  --color-dorado: #b29332;
  --color-turquesa: #30b4ff;
  --color-gris: #cecece;
  --color-fondo-gris: #e9e9e9;
  --color-facebook: #3b5998;
  --color-twitter: #00acee;
  --color-amarillo: #eed202;
  --color-verde: #22bb33;
  --color-warning: #ffd200;
  --color-disabled: #a2a59d;
  --color-naranja: #ed913a;
  --color-error: #f93154;
  --color-award: #C4C4C4;
  --color-next-lot: #858484;

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

body,
html {
  max-width: 1920px;
  margin: 0 auto;
  background-image: linear-gradient(to top, #f3f2f2, #fff);
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

.btn-outline-danger {
  color: var(--color-rojo);
  border: var(--color-rojo) 1.5px solid;
}

.btn-outline-danger:hover {
  color: white;
  background-color: var(--color-rojo);
  border: var(--color-rojo) 1.5px solid;
  transform: scale(1.1);
}

.btn-outline-secondary {
  color: var(--color-azul);
  border: var(--color-azul) 1.5px solid;
}

.btn-outline-secondary:hover {
  color: white;
  border: var(--color-azul) 1.5px solid;
  background-color: var(--color-azul);
  transform: scale(1.1);
}
.btn-outline-secondary:disabled {
  color: var(--color-disabled);
  border: var(--color-disabled) 1.5px solid;
}
.btn-outline-success {
  color: var(--color-verde);
  border: var(--color-verde) 1.5px solid;
}

.btn-outline-success:hover {
  color: var(--color-blanco);
  background-color: var(--color-verde);
  border: var(--color-verde) 1.5px solid;
  transform: scale(1.1);
}
.btn-outline-warning {
  color: var(--color-text);
  border: var(--color-warning) 1.5px solid;
}

.btn-outline-warning:hover {
  color: var(--color-text);
  background-color: var(--color-warning);
  border: var(--color-warning) 1.5px solid;
  transform: scale(1.1);
}
.btn-outline-gold {
  color: var(--color-dorado);
  border: var(--color-dorado) 1.5px solid;
}

.btn-outline-gold:hover {
  color: var(--color-blanco);
  background-color: var(--color-dorado);
  border: var(--color-warning) 1.5px solid;
  transform: scale(1.1);
}
.card {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
}

a {
  color: var(--color-turquesa);
}

input.form-control {
  font-size: 1.6rem;
}

.container-loading {
  font-size: 13rem;
  z-index: 1101;
  position: fixed;
  width: 100%;
  color: var(--color-rojo);
  visibility: hidden;
}

.container-loading.show {
  visibility: visible;
}

.container-loading.hide {
  visibility: hidden;
}

.form-outline .form-control.is-invalid {
  margin-bottom: 0;
  border-color: var(--color-error);
}

.form-outline .form-control.is-invalid ~ .form-label {
  color: var(--color-error);
}
.form-outline .form-control.is-invalid ~ .form-notch .form-notch-leading,
.form-outline .form-control.is-invalid ~ .form-notch .form-notch-middle,
.form-outline .form-control.is-invalid ~ .form-notch .form-notch-trailing {
  border-color: var(--color-error);
}

input[type='date'] {
  white-space: pre;
}

input[type='date'].active {
  white-space: inherit;
}

input[type='date']::before {
  content: attr(placeholder) '                                                                        ';
}

input[type='date']:focus::before {
  content: '' !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.container__users--login {
  margin-right: 20px;
}

.container__users--item.show,
.container__users--login.show {
  visibility: visible;
  display: block;
}

.container__users--item.hide,
.container__users--login.hide {
  visibility: hidden;
  display: none;
}
.dropdown-account:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  font-size: 1.5rem;
}
.btn-user {
  font-size: 2rem;
  background-color: transparent;
  box-shadow: none;
  text-transform: none;
}

.btn-user:active:focus,
.btn-user:active,
.btn-user:focus,
.btn-user:hover {
  box-shadow: none;
}

.btn-user > i {
  font-size: 2.5rem;
  color: var(--color-rojo);
  padding-right: 10px;
}

.dropdown-menu.show {
  font-size: 1.5rem;
}

a.dropdown-item {
  text-transform: uppercase;
}

.modal {
  z-index: 1100;
}

.modal.container__message__parent {
  z-index: 1101;
}
.modal-sm {
  max-width: 400px;
}

.modal .form-label,
.modal .dropdown {
  font-size: 1.2rem;
  text-transform: none;
}

.modal .dropdown i.fas {
  font-size: 1.7rem;
}

a:hover {
  cursor: pointer;
}

.input-group.input-password {
  height: 35px;
}

.input-group.input-password > input {
  height: auto;
}

.input-group.input-password > i {
  font-size: 1.8rem;
  padding-top: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}
.btn-link {
  border-width: 0;
  border-style: none;
  text-transform: capitalize;
  font-size: 1.4rem;
  margin: 0;
  color: var(--color-turquesa);
}

.btn-link:hover {
  background-color: transparent;
  cursor: pointer;
}

.content-vertical-align {
  margin-top: auto;
  margin-bottom: auto;
}

.container-back{
  z-index: 1;
}
.click {
  cursor: pointer;
}