.container__profile,
.container__shoppingCart {
  margin-top: 10%;
  margin-bottom: 3%;
}

.container__profile .list-group-item {
  background-color: transparent;
}

.profile-title {
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.btn-logout {
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.menu-profile {
  font-size: 1.4rem;
  border: 3px solid rgba(0, 0, 0, 0.125);
  text-transform: uppercase;
  font-weight: normal;
}

.menu-profile__title > div {
  font-weight: bold;
  margin-top: 2rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125) !important;
}

.form-profile {
  font-size: 1.4rem;
  border: 3px solid #f2f2f2;
  text-transform: uppercase;
  font-weight: normal;
  background-color: #f2f2f2;
}

.list-group-item {
  border: 0;
  margin-bottom: 8px;
}

.list-group-item-action {
  margin-left: 20px;
  width: 88%;
}

.list-group-item.active {
  background-color: lightskyblue;
  border-color: lightskyblue;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: #e3eeff;
  color: var(--color-text);
  cursor: pointer;
}

.form-profile a {
  text-transform: capitalize;
}

.form-profile input,
.form-profile textarea {
  outline-width: 0px;
}

input.form-control-plaintext.enable {
  color: black;
  background-color: white;
}

.form-profile button {
  font-size: 1.3rem;
  margin-right: 10px;
}

.container__shoppingCart button > i,
.form-profile button > i {
  margin-right: 5px;
}

.inputs-form {
  margin: 2px 5px 0px 5px;
}
.configuration-profile-labels {
  font-size: 1.3rem;
  text-transform: uppercase;
}

.border-title {
  margin-top: 2.3rem;
  margin-bottom: 8px;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

.hide {
  display: none;
}

.show {
  display: block;
}

.btn-profile {
  font-size: 0.9rem;
}

.fa-trash-alt {
  color: var(--color-rojo);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

p.card-text {
  font-size: 1.2rem;
  text-transform: none;
  text-align: justify;
}

small {
  font-size: 1.4rem;
  text-transform: capitalize;
}

input[type='date'].form-control {
  white-space: initial;
}

input[type='date'].form-control::-webkit-calendar-picker-indicator {
  background-origin: content-box;
  background-size: contain;
  height: 1.2em;
  margin-inline-start: 24px;
  opacity: 1;
  padding-bottom: 2px;
  padding-inline-start: 3px;
  padding-inline-end: 3px;
  padding-top: 2px;
  width: 1.2em;
  background-repeat: no-repeat;
  outline: none;
  position: inherit;
  background: revert;
}

.col-sm-8.input-group.input-password {
  width: 66.6666666667%;
}

.input-group.input-password > i {
  z-index: 1001;
  position: absolute;
  margin-left: 90% !important;
}
.auctions-account {
  margin-bottom: 8px;
}

.container__shoppingCart input[type='checkbox'],
.container__shoppingCart input[type='radio'] {
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
}

.container__shoppingCart .form-check {
  margin-left: 3rem;
  margin-bottom: 2rem;
}
.container__shoppingCart .form-check-label {
  margin-left: 1rem;
  font-size: 1.2rem;
  margin-top: -1.5rem;
}

.container__shoppingCart button.btn {
  font-size: 1.3rem;
  margin-right: 10px;
  min-width: auto !important;
}

.container__shoppingCart button.btn-link {
  font-size: 1.3rem !important; /*Boton de confirmacion de orden de compra*/
}

.container__shoppingCart a {
  font-size: 1.4rem;
  cursor: pointer;
}

.container__shoppingCart p {
  font-size: 1.4rem;
}

.container__shoppingCart .text-notice {
  font-size: 1.5rem;
  color: var(--color-rojo);
}

.container__shoppingCart .img-Payment {
  margin-top: -1rem;
}

.container__shoppingCart .img-Payment2 {
  margin-top: -3rem;
}

.container__shoppingCart .img-Payment3 {
  margin-top: -2rem;
}

.container__shoppingCart .fa-cart-arrow-down {
  font-size: 6.5rem;
  color: var(--color-azul);
}

.container__shoppingCart .title__thanks {
  margin-left: 30px;
  font-size: 2.5rem;
  color: var(--color-dorado);
  letter-spacing: 0.7rem;
  text-transform: uppercase;
}

.container__shoppingCart .subtile__thanks {
  margin-left: 30px;
  font-size: 2rem;
  letter-spacing: 0.1rem;
}

.container__orders .container__orders-title {
  font-size: 1.2rem;
  margin-left: 15px;
}

.container__orders .container__orders-value {
  font-size: 1.2rem;
  text-transform: none;
}

.container__modaladress button {
  margin-right: 0;
}
.address-default {
  font-size: 2rem;
  color: var(--color-dorado);
}
.address-default:hover {
  transform: scale(1.09);
}
.accordion-button:hover {
  background-color: var(--color-menu);
}
.retry-payment {
  margin-top: auto;
}
.title-rfc {
  margin-left: 5rem;
}
.modal-body__bids {
  max-height: 80vh;
  overflow-y: auto;
}

.label-value {
  display: inline;
}

@media (max-width: 1750px) {
  .container__shoppingCart .form-check-label {
    margin-top: -2.5rem;
  }
}

@media (max-width: 1500px) {
  .container__shoppingCart .form-check-label {
    margin-top: -1.5rem;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--color-rojo);
}

@media (max-width: 1399px) {
  .retry-payment {
    margin-top: 8px;
  }
}
@media (max-width: 1200px) {
  .container__awardActive small,
  .container__awardActive button {
    font-size: 1.1rem;
  }
  .container__awardActive img {
    width: 90%;
  }
  .container__shoppingCart {
    margin-top: 15%;
  }

  .container__shoppingCart p,
  .container__shoppingCart .card-text,
  .container__shoppingCart small {
    font-size: 1.4rem;
  }
  .container__shoppingCart button.btn {
    font-size: 1.1rem;
  }
}

@media (max-width: 1100px) {
  .container__profile {
    margin-top: 13%;
    max-width: 90%;
    margin-bottom: 5%;
  }

  .configuration-profile-labels,
  .container__addressAccount p {
    font-size: 1.2rem;
  }

  .container__addressAccount i {
    margin-left: 10px;
  }

  .container__shoppingCart p,
  .container__shoppingCart .card-text,
  .container__shoppingCart small {
    font-size: 1.2rem;
  }
  .container__shoppingCart button.btn {
    font-size: 0.9rem;
  }
  .container__shoppingCart .form-check-input[type='radio'] {
    margin-right: 0;
  }

  .container__shoppingCart .form-check-label {
    margin-top: -1rem;
  }
}

@media (max-width: 900px) {
  .container__orders .container__orders-value,
  .container__orders .container__orders-title {
    font-size: 0.9rem;
  }
  .container__orders button {
    font-size: 1rem;
  }

  .container__shoppingCart p,
  .container__shoppingCart .card-text,
  .container__shoppingCart small {
    font-size: 1.1rem;
  }
  .container__shoppingCart button.btn {
    font-size: 0.8rem;
  }

  .container__shoppingCart .fa-cart-arrow-down {
    font-size: 5.5rem;
  }

  .container__shoppingCart .title__thanks {
    font-size: 2rem;
  }

  .container__shoppingCart .subtile__thanks {
    font-size: 1.5rem;
  }
}

@media (max-width: 850px) {
  .container__profile {
    margin-top: 15%;
    max-width: 95%;
    margin-bottom: 8%;
  }

  .container__shoppingCart p,
  .container__shoppingCart .card-text,
  .container__shoppingCart small {
    font-size: 0.9rem;
  }
  .container__shoppingCart button.btn {
    font-size: 0.7rem;
  }
}

@media (max-width: 768px) {
  .form-profile {
    margin-top: 3%;
  }

  .container__awardActive img {
    width: 50%;
  }

  .container__orders .container__orders-value,
  .container__orders .container__orders-title {
    font-size: 1.2rem;
  }
  .container__orders button {
    font-size: 1.3rem;
  }
  .configuration-profile-labels,
  .container__addressAccount p {
    font-size: 1.4rem;
  }

  .container__addressAccount i {
    margin-left: 0;
  }

  .container__shoppingCart .fa-cart-arrow-down {
    font-size: 4.5rem;
  }

  .container__shoppingCart .title__thanks {
    font-size: 1.5rem;
  }

  .container__shoppingCart .subtile__thanks {
    font-size: 1rem;
  }
}

@media (max-width: 650px) {
  .container__profile {
    margin-top: 18%;
  }

  .profile-title {
    font-size: 1.5rem;
  }
  .btn-logout {
    font-size: 1.3rem;
  }

  .container__shoppingCart {
    margin-top: 23%;
  }

  .container__shoppingCart .fa-cart-arrow-down {
    font-size: 3.5rem;
  }

  .container__shoppingCart .title__thanks {
    font-size: 1rem;
  }

  .container__shoppingCart .subtile__thanks {
    font-size: 0.5rem;
  }
}

@media (max-width: 575px) {
  .profile-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  .btn-logout {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .form-profile button {
    font-size: 1.1rem;
  }
  .container__awardActive img {
    width: 100%;
  }
  .container__awardActive button {
    margin-top: 20px;
  }

  .container__orders button {
    margin-top: 20px;
  }
  .configuration-profile-labels,
  .container__addressAccount p {
    font-size: 1.2rem;
  }

  .container__addressAccount i {
    margin-left: 10px;
  }

  .container__modaladress i {
    margin-left: 0;
  }

  .container__shoppingCart input[type='checkbox'],
  .container__shoppingCart input[type='radio'] {
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
  }
}

@media (max-width: 500px) {
  .container__profile {
    margin-top: 22%;
  }
  .form-profile {
    margin-top: 5%;
  }
  .container__profile h4 {
    font-size: 1.1rem;
  }
  .form-profile button {
    font-size: 0.9rem;
  }

}

@media (max-width: 450px) {
  .configuration-profile-labels,
  .container__addressAccount p {
    font-size: 1rem;
  }

  .container__addressAccount i {
    margin-left: 20px;
  }
  .container__modaladress i {
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .container__profile {
    margin-top: 30%;
  }

  .container__orders .container__orders-value,
  .container__orders .container__orders-title {
    font-size: 0.9rem;
  }

  .container__shoppingCart input[type='checkbox'],
  .container__shoppingCart input[type='radio'] {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
  }

  .container__shoppingCart .card.show {
    margin-top: 5%;
  }

  .container__shoppingCart .fa-cart-arrow-down {
    font-size: 3rem;
  }

  .container__shoppingCart .title__thanks {
    font-size: 0.8rem;
  }
}

@media (max-width: 350px) {
  .configuration-profile-labels,
  .container__addressAccount p,
  .container__addressAccount button {
    font-size: 1rem;
  }
}
