.container-carousel {
  margin-top: 5.5%;
}

.img-carousel {
  padding-left: 0;
  padding-right: 0;
}

.carousel-item.active > img {
  margin-left: 55%;
  width: 45vw;
  height: 38vw;
}
.carousel-item.active img.announcement {
  width: 100%;
}

.carousel-control-next-icon:after,
.carousel-control-prev-icon:after {
  font-size: 4rem;
  color: #808080;
  content: none;
}

.carousel-control-prev-icon {
  width: 5rem;
  height: 5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  width: 5rem;
  height: 5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-control-next,
.carousel-control-prev {
  width: 5%;
  opacity: 1;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 0.5;
}

.carousel-indicators [data-bs-target] {
  padding-bottom: 15px;
  width: 15px;
  height: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.carousel-caption h5 {
  font-size: 3rem;
}

.carousel-caption p {
  font-size: 1.5rem;
}

.container-carousel img {
  width: 100%;
  height: 630px;
}

.carousel-item .panel__left {
  margin-top: 70px;
}
.carousel-item h1 {
  font-size: 5rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.carousel-item h2 {
  /*position: absolute;
  z-index: 1001;
  left: 15%;
  top: 20%;*/
  font-size: 4rem;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
  border-top: 1px var(--color-dorado) solid;
  border-bottom: 1px var(--color-dorado) solid;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.carousel-item h3 {
  /*position: absolute;
  z-index: 1001;
  left: 15%;
  top: 35%;*/
  font-size: 2.4rem;
  text-transform: uppercase;
}
.carousel-item span {
  /*position: absolute;
  z-index: 1001;
  left: 15%;
  top: 8%;*/
  font-size: 4rem;
}
.carousel-item button {
  /*position: absolute;
  z-index: 1001;
  left: 15%;
  top: 50%;*/
  font-size: 2.6rem;
}

/*.carousel-item button.btn-carousel-secondary {
  top: 63%;
}*/

.carousel-item p {
  /*position: absolute;
  z-index: 1001;
  left: 15%;
  right: 50%;*/
  font-size: 2.3rem;
  font-style: italic;
  margin-bottom: 0;
}

/*.carousel-item p.carousel-item__description {
  top: 75%;
}*/

.carousel-item p.carousel-item__place {
  top: 90%;
}

.container-auctions {
  margin-top: 5%;
}

.container-auctions h2 {
  text-transform: uppercase;
  letter-spacing: 10px;
  font-size: 4rem;
}

.container-auctions-row {
  margin-top: 5px;
}

.container-auctions--title {
  font-weight: bold;
  color: var(--color-dorado);
}

.container-auctions--subtitle {
  border-top: 1px var(--color-dorado) solid;
  border-bottom: 1px var(--color-dorado) solid;
  text-transform: uppercase;
  letter-spacing: 7.2px;
  font-size: 4.2rem;
}

.container-auctions--subtitle2 {
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 2.4rem;
}

.container-auctions-row button {
  text-transform: uppercase;
  font-size: 2.6rem;
  letter-spacing: 0.8px;
}

.container-auctions-row .container-auctions-col {
  text-align: end;
  margin-top: 25px;
}

.container-auctions-col.container-auctions-btn2 {
  margin-top: -50px;
}

.container-auctions--description {
  font-size: 2.6rem;
}

.card .card-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: 5px;
}

/*.container-auctions-items {
  margin-right: 3%;
}*/

.container-auctions-items--img {
  width: 235px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.container-auctions-items--title {
  border-top: var(--color-dorado) 1px solid;
  padding-top: 30px;
  font-size: 2.8rem;
  font-weight: normal;
  color: var(--color-text);
}

.container-auctionsHighlight {
  margin-top: 5%;
  margin-bottom: 3%;
}

.container-auctionsHighlight h2 {
  letter-spacing: 10px;
  font-size: 4rem;
}

.container-auctionsHighlight--title {
  font-weight: bold;
  color: var(--color-azul);
}

.container-auctionsHighlight-row {
  margin-top: 20px;
}

.container-auctionsHighlight--description {
  margin-top: 25px;
  font-size: 2.6rem;
}

.container-auctionsHighlight-row button {
  text-transform: uppercase;
  font-size: 2.6rem;
}

.container-auctionsHighlight-btn2 {
  margin-top: -60px;
}

.card-footer {
  margin-bottom: 4%;
}

.container-auctions-button {
  margin-top: 4%;
}

.container-video {
  padding-top: 4%;
  padding-bottom: 4%;
  text-align: center;
  background-image: url('../assets/BackgroundVideo.png');
  background-size: cover;
}

.container-video--title,
.container-video--title2 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 4rem;
  margin-bottom: 4%;
  font-weight: 500;
}

.container-video--title {
  color: var(--color-rojo);
}

.container-video--subtitle {
  margin-bottom: 4%;
  text-align: center;
  font-size: 2.6rem;
}

.container-video--description {
  margin-top: 20px;
}

.mls-title {
  color: var(--color-rojo);
  font-weight: bold;
}

.ratio-custom {
  --bs-aspect-ratio: calc(9 / 16 * 70%);
  height: 50vw;
}

.ratio-custom > iframe {
  width: 90%;
  height: 100%;
}

.container-video--footer {
  text-align: center;
  font-size: 2.6rem;
}

.btn-video--footer {
  font-size: 2.6rem;
  text-transform: uppercase;
}

.container-auctionsHighlights--title {
  border-top: var(--color-azul) 1px solid;
  font-size: 1.6rem;
  padding-top: 20px;
}

.container-auctionsHighlight-row .card-footer button {
  font-size: 1.5rem;
}
.container-auctionsHighlight-row .modal-footer button {
  font-size: 1.5rem;
}

.card-text {
  font-size: 1.6rem;
}
.product-text {
  font-size: 1.4rem;
}

@media (min-width: 1900px) {
  .container-carousel {
    max-height: 722px;
  }
  .carousel-item {
    max-height: 722px;
  }

  .carousel-item.active > img {
    margin-left: 50%;
    width: 50%;
    height: 722px;
  }
}

@media (max-width: 1850px) {
  .container-auctions--subtitle {
    letter-spacing: 5px;
  }
}

@media (max-width: 1800px) {
  .container-carousel {
    margin-top: 6%;
  }
}

@media (max-width: 1500px) {
  .container-carousel {
    margin-top: 7%;
  }
  .container-auctions-items--img {
    width: 210px;
  }
}

@media (max-width: 1400px) {
  .carousel-item h1,
  .carousel-item h2 {
    font-size: 3.5rem;
  }

  .carousel-item h3 {
    font-size: 2rem;
  }

  .carousel-item button {
    font-size: 2rem;
  }

  .carousel-item p {
    font-size: 1.8rem;
  }

  .container-auctions h2 {
    font-size: 3rem;
  }

  .container-auctions-row button {
    font-size: 2rem;
  }

  .container-auctions--subtitle {
    font-size: 4rem;
  }
  .container-auctions--subtitle2 {
    font-size: 2rem;
  }
  .container-auctions--description {
    font-size: 2rem;
  }

  .container-auctions-items--ml,
  .container-auctionsHighlight-items--ml {
    margin-left: 4%;
  }

  .container-auctionsHighlight h2 {
    font-size: 3rem;
  }
  .container-auctionsHighlight--description {
    font-size: 2rem;
  }
  .container-auctionsHighlight-row button {
    font-size: 2rem;
  }
  .container-auctionsHighlight-row .card-footer button {
    font-size: 1.3rem;
  }
  .container-auctionsHighlight-row .modal-footer button {
    font-size: 1.1rem;
  }
  .container-video--title,
  .container-video--title2 {
    font-size: 3rem;
  }

  .container-video--subtitle {
    font-size: 2rem;
  }
  .container-video--footer {
    font-size: 2rem;
  }
  .btn-video--footer {
    font-size: 2rem;
  }
}

@media (max-width: 1300px) {
  .container-carousel {
    margin-top: 8%;
  }
  .carousel-item .panel__left {
    margin-top: 25px;
  }
  .container-carousel img {
    height: 500px;
  }
  .carousel-item h1,
  .carousel-item h2 {
    font-size: 3rem;
  }
  .container-auctions--subtitle {
    font-size: 3rem;
    letter-spacing: 4px;
  }
}

@media (max-width: 1250px) {
  .carousel-item p {
    font-size: 1.6rem;
  }

  .carousel-item button {
    font-size: 1.8rem;
  }
}

@media (max-width: 1200px) {
  .container-carousel {
    margin-top: 9%;
  }
}

@media (max-width: 1025px) {
  .container-carousel {
    margin-top: 10.5%;
  }

  .carousel-item h1,
  .carousel-item h2 {
    font-size: 2.5rem;
  }

  .carousel-item button {
    font-size: 1.4rem;
  }
  .carousel-item p {
    font-size: 1.2rem;
  }

  .container-carousel img {
    height: 380px;
  }

  .container-auctions-col.container-auctions-btn2 {
    margin-top: -40px;
  }
  .container-auctions--description {
    font-size: 1.5rem;
  }

  .container-auctions h2 {
    font-size: 2.5rem;
  }

  .container-auctions-row button {
    font-size: 1.5rem;
  }

  .container-auctions--subtitle {
    font-size: 2.5rem;
    letter-spacing: 2px;
  }
  .container-auctions--subtitle2 {
    font-size: 1.6rem;
  }
  .container-auctions-items--img {
    width: 180px;
  }
  .card .card-title {
    font-size: 1.7rem;
  }
  .container-auctions-items {
    margin-right: 0;
  }
  .container-auctionsHighlight h2 {
    font-size: 3rem;
  }
  .container-auctionsHighlight--description {
    font-size: 1.5rem;
  }
  .container-auctionsHighlight-row button {
    font-size: 1.5rem;
  }

  .container-video--title,
  .container-video--title2 {
    font-size: 3rem;
  }
}

@media (max-width: 950px) {
  .container-carousel {
    margin-top: 11%;
  }
}

@media (max-width: 850px) {
  .container-carousel {
    margin-top: 12.5%;
  }

  .carousel-item h1,
  .carousel-item h2 {
    font-size: 2rem;
  }

  .carousel-item h3 {
    font-size: 1.5rem;
  }

  .carousel-item button {
    font-size: 1.2rem;
  }

  .container-carousel img {
    height: 350px;
  }

  .container-auctions h2,
  .container-auctions--subtitle {
    font-size: 2rem;
  }

  .container-auctions-row button,
  .container-auctions--subtitle2 {
    font-size: 1.1rem;
  }

  .container-auctionsHighlight-row button {
    font-size: 1.2rem;
  }
}

@media (max-width: 800px) {
  .container-carousel {
    margin-top: 13.5%;
  }
}

@media (max-width: 769px) {
  /*.carousel-item span {
    top: 0;
  }*/
  .container-carousel {
    margin-top: 12.1%;
  }

  .carousel-item button {
    font-size: 1rem;
  }

  .carousel-item h1,
  .carousel-item h2 {
    font-size: 1.5rem;
  }

  .carousel-item p {
    font-size: 1rem;
  }

  .carousel-item p.carousel-item__place {
    top: 85%;
  }

  .container-carousel img {
    height: 300px;
  }

  /*.carousel-indicators {
    bottom: -45px;
  }*/

  .container-auctions--subtitle {
    font-size: 1.5rem;
  }

  .container-auctions--subtitle2 {
    font-size: 1rem;
  }

  .container-auctions-row > .mt-5 {
    margin-top: 0.5rem !important;
  }

  .container-auctions-row > .mb-5 {
    margin-bottom: 0.5rem !important;
  }

  .container-auctions-row button {
    font-size: 1rem;
  }
  .container-auctions-col.container-auctions-btn2 {
    margin-top: -15px;
  }

  .container-auctions--description {
    font-size: 1.1rem;
  }

  .container-auctions-items {
    margin-right: 0;
  }

  .container-auctions-items--img {
    width: 150px;
  }

  .card .card-title {
    font-size: 1.5rem;
  }

  .container-auctionsHighlight h2 {
    font-size: 2.2rem;
  }
  .container-auctionsHighlight-row button {
    font-size: 1rem;
  }
  .container-auctionsHighlight--description {
    font-size: 1rem;
  }
  .container-video--title,
  .container-video--title2 {
    font-size: 2.5rem;
  }
  .btn-video--footer {
    font-size: 1rem;
  }
  .btn-out-streaming {
    margin-top: -14px;
  }
}

@media (max-width: 700px) {
  .container-carousel {
    margin-top: 13.8%;
  }

  .carousel-item h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 650px) {
  .container-carousel {
    margin-top: 15%;
  }
  .carousel-item h1,
  .carousel-item h2 {
    font-size: 1.5rem;
  }
  .carousel-item h3 {
    font-size: 1rem;
  }
  .carousel-item button {
    font-size: 0.8rem;
  }
  .carousel-item p {
    font-size: 0.8rem;
  }

  .container-carousel img {
    height: 250px;
  }
  .container-auctions h2,
  .container-auctions--subtitle {
    font-size: 1.2rem;
  }
  .container-auctions--subtitle2 {
    font-size: 0.9rem;
    letter-spacing: 4px;
  }
  .container-auctions-items--img {
    width: 120px;
  }

  .container-auctionsHighlight h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .container-loading img {
    width: 30%;
  }
  .carousel-indicators [data-bs-target] {
    padding-bottom: 15px;
    width: 15px;
  }

  .carousel-item span {
    font-size: 1.1rem;
  }

  .carousel-img {
    display: none;
  }

  .container-carousel {
    margin-top: 15.8%;
  }

  .carousel-item p {
    font-size: 0.8rem;
  }

  .carousel-item h1,
  .carousel-item h2 {
    font-size: 1rem;
  }

  .carousel-item h3 {
    font-size: 1rem;
  }

  .carousel-item button {
    font-size: 0.8rem;
  }

  .text-center-mobile {
    text-align: center !important;
  }

  .container-auctions {
    margin-top: 10%;
  }

  .container-auctions h2 {
    text-align: center;
  }

  .container-auctions--subtitle2 {
    font-size: 1rem;
  }
  .card .card-title.container-auctions-items--title {
    font-size: 1.5rem;
    text-align: center;
  }
  button.watch-catalog {
    margin-top: 15px;
  }
  .auctionsCarousel-indicators {
    bottom: -15%;
  }
  .container-auctionsHighlight {
    margin-top: 10%;
    margin-bottom: 3%;
  }
  .container-auctionsHighlight--description {
    font-size: 1.1rem;
  }
  .container-auctionsHighlight-btn2 {
    margin-top: 0;
  }
  .container-auctions-button {
    margin-top: 15%;
  }

  .container-auctions-button button {
    font-size: 1rem;
  }
}

@media (max-width: 525px) {
  .container-carousel {
    margin-top: 17%;
  }

  .carousel-item h3 {
    font-size: 1rem;
  }

  /*.carousel-item h1 {
    font-size: 2rem;
  }*/
  .carousel-item button {
    font-size: 0.8rem;
  }
  .carousel-item p {
    font-size: 0.8rem;
  }
}

@media (max-width: 460px) {
  .container-carousel {
    margin-top: 20%;
  }

  .carousel-item h3 {
    font-size: 1rem;
  }

  .carousel-item p {
    font-size: 0.6rem;
  }

  .carousel-item h1 {
    font-size: 1.3rem;
  }

  .container-carousel img {
    height: 220px;
  }
}

@media (max-width: 420px) {
  .carousel-item h1 {
    font-size: 1rem;
  }
  .carousel-item h2,
  .carousel-item h3 {
    font-size: 0.8rem;
  }
  .carousel-item button {
    font-size: 0.6rem;
  }
}

@media (max-width: 360px) {
  .carousel-item .panel__left {
    margin-top: 20px;
  }
  .mt-mobile {
    margin-top: 0.5rem !important;
  }
  .mb-mobile {
    margin-bottom: 0.5rem !important;
  }
  .container-carousel {
    margin-top: 25%;
  }
  .carousel-item h3 {
    font-size: 0.6rem;
  }

  .container-carousel img {
    height: 180px;
  }

  .carousel-item p {
    font-size: 0.6rem;
  }

  .carousel-item h1,
  .carousel-item h2 {
    font-size: 0.6rem;
  }
  .carousel-item button {
    font-size: 0.5rem;
  }
  .carousel-indicators {
    bottom: -35px;
  }
  .container-auctions-items--img {
    width: 130px;
  }
}
