.container-auctions-menu {
  width: 90%;
  margin-bottom: 10%;
  margin-top: 8%;
}
.title-section-auc {
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 3.4rem;
  text-transform: uppercase;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 6px;
  text-align: left;
  color: var(--color-dorado);
}
.title-section-auc .auctions2 {
  color: var(--color-text);
}
.image-col img.auction-image {
  width: 100%;
  height: 520px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.auction-text1 {
  margin-top: 50px;
  margin-left: 50px;
  text-transform: uppercase;
  font-size: 5rem;
  line-height: normal;
  letter-spacing: 4.8px;
  text-align: left;
  color: var(--color-text);
}
.auction-text2 {
  margin-left: 50px;
  max-width: 495px;
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border-top: 2px solid var(--color-dorado);
  border-bottom: 2px solid var(--color-dorado);
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--color-text);
}
.auction-text3 {
  margin-left: 50px;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 5px;
  text-align: left;
  color: var(--color-text);
}
.watch-catalog {
  font-size: 2.6rem;
  margin-top: 30px;
  margin-left: 50px;
}

.reg-ofer {
  font-size: 2.6rem;
  margin-top: 20px;
  margin-left: 50px;
}

.note-auction {
  margin-top: 30px;
  margin-left: 50px;
  font-size: 2rem;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--color-text);
}
.text-methods {
  margin-bottom: 50px;
  font-size: 2.6rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: var(--color-text);
}

.card-bottom {
  margin-top: 50px;
}

.container_payment_methods > div {
  font-size: 2.5rem;
  letter-spacing: 0.7px;
  border-radius: 3px;
  border: solid 2px var(--color-gris);
  margin-right: 25px;
  width: 12%;
}

.container_payment_methods--box > p {
  font-size: 1.8rem;
  text-transform: uppercase;
}

.container_payment_methods--box .transfer {
  width: 50%;
  margin-top: 30px;
  padding-bottom: 15px;
}

.container_payment_methods--box .cash {
  width: 60%;
  margin-top: 30px;
}

.container_payment_methods--box .amex {
  width: 60%;
  margin-top: 30px;
}

.container_payment_methods--box .visa {
  width: 70%;
  margin-top: 50px;
}

.container_payment_methods--box .mastercard {
  width: 85%;
  margin-top: 20px;
}

@media (min-width: 1780px) {
  .container-auctions-menu {
    max-width: 1587px;
  }
}

@media (max-width: 1700px) {
  .container_payment_methods--box > p {
    font-size: 1.5rem;
  }
}

@media (max-width: 1560px) {
  .auction-text1 {
    font-size: 3.5rem;
  }
  .auction-text2 {
    font-size: 3.5rem;
  }
  .auction-text3 {
    font-size: 1.8rem;
  }

  .watch-catalog {
    font-size: 2rem;
  }
  .reg-ofer {
    font-size: 2rem;
  }
  .note-auction {
    font-size: 1.6rem;
  }
}

@media (max-width: 1450px) {
  .watch-catalog {
    font-size: 1.8rem;
  }
  .reg-ofer {
    font-size: 1.8rem;
  }
  .note-auction {
    font-size: 1.4rem;
  }
  .container_payment_methods > div {
    font-size: 1.8rem;
  }

  .container_payment_methods--box > p {
    font-size: 1.1rem;
  }

  .container_payment_methods--box .visa {
    margin-top: 35px;
  }
}

@media (max-width: 1340px) {
  .image-col img.auction-image {
    width: 550px;
    height: 400px;
  }

  .watch-catalog {
    font-size: 1.4rem;
  }
  .reg-ofer {
    font-size: 1.4rem;
  }

  .container_payment_methods > div {
    width: 13.5%;
  }
}

@media (max-width: 1250px) {
  .image-col img.auction-image {
    width: 460px;
    height: 400px;
  }
}

@media (max-width: 1200px) {
  .auction-text2 {
    font-size: 3rem;
  }
  .auction-text3 {
    font-size: 1.5rem;
  }
  .note-auction {
    font-size: 1.2rem;
  }
}

@media (max-width: 1025px) {
  .container-auctions-menu {
    margin-top: 15%;
    margin-bottom: 10%;
  }
  .title-section-auc {
    margin: 0 29px 60px 0px;
  }
  .card-bottom {
    margin-top: 80px;
  }
  .auction-text2 {
    font-size: 2.5rem;
  }
  .auction-text3 {
    font-size: 1.2rem;
  }
  .image-col img.auction-image {
    width: 400px;
    height: 360px;
  }

  .watch-catalog {
    font-size: 1.2rem;
  }
  .reg-ofer {
    font-size: 1.2rem;
  }
  .text-methods {
    font-size: 2.3rem;
  }

  .container_payment_methods > div {
    font-size: 1.1rem;
    width: 14.5%;
  }
  .container_payment_methods--box > p {
    font-size: 1.1rem;
  }
}

@media (max-width: 900px) {
  .auction-text1 {
    font-size: 3rem;
  }
  .auction-text2 {
    font-size: 2rem;
  }
  .auction-text3 {
    font-size: 0.8rem;
  }
  .note-auction {
    font-size: 0.8rem;
  }

  .image-col img.auction-image {
    width: 350px;
    height: 300px;
  }

  .image-col {
    margin-top: auto;
    margin-bottom: auto;
  }

  .container_payment_methods--box > p {
    font-size: 0.8rem;
  }
  .container_payment_methods--box .cash {
    padding-bottom: 5px;
  }
}

@media (max-width: 850px) {
  .auction-text1 {
    font-size: 2.5rem;
  }
  .auction-text2 {
    font-size: 1.5rem;
  }
  .auction-text3 {
    letter-spacing: 4px;
  }
  .btnAuction {
    font-size: 1.1rem;
  }
}

@media (max-width: 769px) {
  .image-col img.auction-image {
    width: 300px;
    height: 300px;
  }
  .watch-catalog {
    font-size: 1.2rem;
  }
  .reg-ofer {
    font-size: 1.2rem;
  }
  .note-auction {
    font-size: 1rem;
  }
  .container_payment_methods--box > p,
  .container_payment_methods > div {
    font-size: 0.8rem;
  }
}

@media (max-width: 670px) {
  .container-auctions-menu {
    margin-top: 20%;
  }
  .title-section-auc {
    font-size: 2.5rem;
  }

  .auction-text3 {
    letter-spacing: 0;
  }
  .buttons-auctions {
    font-size: 1rem;
  }
  .image-col img.auction-image {
    width: 260px;
    height: 280px;
  }
  .container_payment_methods--box > p {
    font-size: 0.7rem;
  }
}

@media (max-width: 575px) {
  .title-section-auc {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
  .auction-text1 {
    margin-top: 15px;
  }
  .auction-text1,
  .auction-text2 {
    font-size: 2rem;
  }

  .auction-text1,
  .auction-text3 {
    margin-left: 0;
  }
  .auction-text2 {
    width: 85%;
    margin: 0 auto;
  }
  .auction-text3 {
    margin-top: 5px;
    font-size: 1.5rem;
  }
  .buttons-auctions {
    font-size: 1.3rem;
    margin-left: 0;
  }
  .note-auction {
    margin-left: 0;
  }

  .text-methods {
    font-size: 1.8rem;
  }

  .image-col img.auction-image {
    width: 350px;
    height: 300px;
  }

  .container_payment_methods > div {
    width: 40%;
    margin-right: 10px;
    margin-bottom: 15px;
    height: 160px;
  }
  .container_payment_methods--box > p {
    font-size: 1.3rem;
  }
  .container_payment_methods--box .transfer {
    margin-top: 20px;
  }
  .container_payment_methods--box .cash {
    margin-top: 13px;
  }
  .container_payment_methods--box .mastercard {
    width: 70%;
    margin-top: 20px;
  }
  .container_payment_methods--box .visa {
    width: 80%;
    margin-top: 30px;
  }
  .container_payment_methods--box .amex {
    margin-top: 20px;
  }
}

@media (max-width: 450px) {
  .image-col img.auction-image {
    width: 300px;
    height: 250px;
  }
}

@media (max-width: 415px) {
  .container-auctions-menu {
    margin-top: 30%;
  }
  .title-section-auc {
    font-size: 1.8rem;
  }
  .auction-text1,
  .auction-text2 {
    font-size: 1.5rem;
  }

  .btnAuction {
    font-size: 1rem;
  }

  .auction-text3 {
    font-size: 1.1rem;
  }

  .container_payment_methods > div {
    height: 130px;
  }
  .container_payment_methods--box .cash {
    margin-top: 15px;
  }

  .container_payment_methods--box > p {
    font-size: 1.2rem;
  }
}

@media (max-width: 380px) {
  .image-col img.auction-image {
    width: 200px;
    height: 200px;
  }
}
