.container__expire {
  margin-top: 15%;
  margin-bottom: 15%;
}

.container__expire label {
  margin-top: -20px;
  font-size: 3rem;
  color: var(--color-rojo);
}

.container__expire i {
  font-size: 3.5rem;
  margin-left: 10px;
  margin-right: 10px;
  color: var(--color-rojo);
}

.container__expire-content {
  border: 5px var(--color-rojo);
  border-style: double;
}

@media (max-width: 992px) {
  .container__expire {
    margin-top: 25%;
  }
}

@media (max-width: 769px) {
  .container__expire label {
    font-size: 2rem;
  }

  .container__expire i {
    font-size: 2.5rem;
  }
}

@media (max-width: 500px) {
  .container__expire {
    margin-top: 30%;
  }

  .container__expire label {
    font-size: 1.5rem;
  }

  .container__expire i {
    font-size: 2rem;
  }
}

@media (max-width: 400px) {
  .container__expire {
    margin-top: 35%;
  }

  .container__expire label {
    font-size: 1.2rem;
  }

  .container__expire i {
    font-size: 1.7rem;
  }
}
