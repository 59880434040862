#myframe{
    width: 500px;
    height: 600px;
    border: none;
}
@media only screen and (width <= 980px) {
    #myframe{
        width: 100%;
        height: 440px;
    }
}
