.container-input-checkin {
  margin: 0 auto;
  font-size: 1.2rem;
}

.modal-header-login .form-check-input[type='checkbox'] {
  font-size: 1.5rem;
  transform: scale(1.5);
}
.input-group.input-password.container-input-checkin {
  width: 83%;
}

@media (max-width: 575px) {
  .container-input-checkin.input-group.input-password > i {
    margin-left: 85% !important;
  }
  .container-captcha{
    width: 100%;
  }
}
