.modal-header-login {
  border: 0;
}

.modal-header-login img {
  margin: 0 auto;
}

.modal-header-login button,
.modal-header-login a,
.modal-header-login label {
  font-size: 1.1rem;
}

.input-group.input-password.container-input-login {
  width: 100%;
  margin: 0 auto;
  font-size: 1.2rem;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
