.container-about-us {
  width: 90%;
  margin-top: 10%;
  margin-bottom: 3%;
}
.title-section-s {
  width: 100%;
  height: 29px;
  margin: 0 29px 43px 0px;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 6px;
  text-align: left;
  color: var(--color-dorado);
}

.title-section-s > div > span {
  color: var(--color-text);
}
.we-platform {
  width: 100%;
  height: auto;
  margin: 43px 0px 47px 0px;
  padding-left: 10px;
  padding-right: 40px;
  font-size: 1.8rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: var(--color-text);
}
p > span {
  font-weight: 600;
}

.cards-section {
  height: auto;
  width: 50%;
}
.image-container {
  padding-left: 40px;
}
.image-container img.about-us-image {
  width: 100%;
  object-fit: cover;
}
.card-item {
  height: 185px;
  margin-bottom: 8px;
}
.center-texts {
  display: flex;
  align-items: center;
  color: var(--color-blanco);
  width: 299px;
  text-transform: uppercase;
  flex-grow: 0;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.95);
  font-weight: bold;
  font-size: 1.8rem;
  letter-spacing: 3.8px;
  line-height: normal;
}
.style-4 {
  font-size: 3.3rem;
  font-weight: bold;
  letter-spacing: 3.6px;
}

.parent {
  position: relative;
}
.opacity_div {
  background: var(--color-blanco);
  height: 185px;
  width: 50%;
  position: absolute;
  opacity: 0.2;
}

@media (min-width: 1780px) {
  .container-about-us {
    max-width: 1587px;
  }
}

@media (max-width: 1250px) {
  .container-about-us {
    margin-top: 13%;
  }
  .image-container {
    padding-left: 0;
  }
}

@media (max-width: 1025px) {
  .title-section-s {
    width: 100%;
    height: 19px;
    margin: 0 29px 30px 0px;
    font-size: 1.6rem;
  }

  .we-platform {
    margin: 15px 0px 40px 0px;
    font-size: 1.6rem;
  }
  .container-about-us {
    margin-top: 15%;
    margin-bottom: 11%;
  }
}
@media (max-width: 769px) {
  .title-section-s {
    width: 100%;
    height: 9px;
    margin: 0 29px 20px 0px;
    font-size: 1.6rem;
  }

  .we-platform {
    margin: 15px 0px 20px 0px;
    font-size: 1.6rem;
    padding-right: 0px;
    padding-left: 0px;
  }
  .image-container {
    padding-left: 0px;
  }
  .container-about-us {
    margin-top: 18%;
    margin-bottom: 5%;
  }
}

@media (max-width: 500px) {
  .container-about-us {
    margin-top: 25%;
  }
  .title-section-s,
  .we-platform {
    font-size: 1.2rem;
  }
}

@media (max-width: 350px) {
  .container-about-us {
    margin-top: 30%;
  }
  .title-section-s,
  .we-platform {
    font-size: 1rem;
  }
}
