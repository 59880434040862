.dropdown-year {
  border-radius: 3px;
  border: 1px solid gainsboro;
  z-index: 0;
}
.dropdown-year > button {
  width: 100%;
  font-size: 1.2rem;
}
.text-expiration {
  text-transform: capitalize;
}
.body-streaming-message {
  width: 70%;
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}
.body-streaming-message .auction-streaming {
  font-size: 1.8rem;
  font-weight: bold;
  color: #383838;
}
.check-streaming {
  font-size: 4rem;
  color: var(--color-dorado);
}
.pallet-streaming {
  font-size: 2.1rem;
  color: var(--color-rojo);
}
.validation-section {
  margin: 0 20px 20px;
}
.button-streaming {
  font-size: 1.6rem;
}
.button-validation {
  font-size: 1.2rem;
}
.body-streaming-message > button {
  font-size: 1.9rem;
}
.date-register {
  font-size: 1.3rem;
}
.input-background {
  margin-top: 1px;
  background-color: var(--color-blanco);
  z-index: 100;
}

.watch-catalog {
  margin-right: auto;
  margin-left: auto;
  font-size: 1.6rem;
}
.button-contact-error {
  color: var(--color-turquesa);
}
.inputs-form-card {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.card-years-options {
  font-size: 1.4rem;
  padding: 0;
}
#input-card-number {
  padding-top: 8px;
}
#input-name-card {
  height: 36px;
}

.container__cardForm input[type='radio'] {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.modal-hidden{
  visibility: hidden;
}
