.menu__streaming {
  margin-right: 50px;
}

.menu__streaming i {
  font-size: 1.8rem;
  margin-right: 5px;
}

.container__Streaming {
  margin-top: 10%;
  margin-bottom: 10%;
}

.container__Streaming--title {
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.container__Streaming--title > span {
  color: var(--color-dorado);
}

.container__Streaming--subtitle {
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.btn-lot {
  font-size: 1.6rem;
}
.btn-confirm {
  font-size: 1.6rem;
  margin-right: 10px;
  width: 90%;
}
.container__streaming--carousel {
  border: 2px solid var(--color-dorado);
  border-radius: 5px;
}

.container__streaming--carousel__item {
  margin-right: 20px;
}
.container__streaming--carousel__item_now {
  margin-right: 70px;
}
.last-lot {
  padding-right: 190px;
}
.card__next {
  width: 120%;
  height: 90%;
  border: 2px solid var(--color-rojo);
}
.card__next:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 2px var(--color-azul);
}
.card__next > img {
  width: 200px;
}

.card__next p.card-text {
  font-size: 0.9rem;
  text-transform: uppercase;
}

.card__next p.card-text.card__next--title {
  font-size: 1.3rem;
}

.card__carousel > img {
  width: 80px;
}
.card__carousel {
  width: 105px;
  margin-bottom: 15px;
}
.card__carousel:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 2px var(--color-azul);
}
.card-header {
  color: var(--color-blanco);
  background-color: var(--color-award);
  text-transform: uppercase;
}
.card-header-next {
  color: var(--color-blanco);
  background-color: var(--color-next-lot);
  text-transform: uppercase;
}
.card-header.header__adjudge {
  background-color: var(--color-naranja);
}

.header__win {
  background-color: var(--color-azul);
}
.card-body.orange__border {
  margin-top: -1px;
  border: 1px solid var(--color-naranja);
  border: 1px solid var(--color-award);
}
.card-body.blue__border {
  margin-top: -1px;
  border: 1px solid var(--color-azul);
  border: 1px solid var(--color-award);
}
.card-body.gray__border {
  margin-top: -1px;
  border-radius: 3px;
  border: 2px solid var(--color-award);
}
.bookmark {
  position: absolute;
  font-size: 1.3rem;
  color: var(--color-dorado);
  top: 35px;
  left: 5px;
}

.bookmark-CurrentLot {
  font-size: 2.5rem;
  color: var(--color-dorado);
  margin-top: -5px;
}

.container__CurrentLot,
.container__awards {
  margin-top: 30px;
}
.container__awards th {
  font-weight: bold;
}
.container__awards td:first-child {
  font-weight: bold;
}
.container__CurrentLot label {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.container__CurrentLot .card {
  align-items: center;
  margin-bottom: 20px;
}

.container__CurrentLot img {
  width: 80%;
}

.container__CurrentLot p {
  font-size: 1.2rem;
  text-align: justify;
}

.container__awards th,
.container__awards td {
  font-size: 1.1rem;
  text-transform: uppercase;
}

.bookmark-CurrentAwards {
  font-size: 1.2rem;
  color: var(--color-dorado);
}

.statusAwardsWin {
  color: var(--color-azul);
}

.statusAwardsLost {
  color: var(--color-naranja);
}

.container__video,
.container__information {
  margin-top: 5%;
  border: 2px solid var(--color-dorado);
  height: 100%;
  border-radius: 5px;
}

.ratio-custom {
  height: 25vw;
}

.container__video--waiting {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 2.5rem;
  color: var(--color-rojo);
  border-bottom: 2px solid var(--color-dorado);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.container__video h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 15px;
  margin-left: 25px;
  margin-bottom: 0;
}

.container__video h1 {
  color: var(--color-rojo);
}

.container__video--offer {
  font-size: 2.3rem;
  color: var(--color-rojo);
  letter-spacing: 2px;
  margin-left: 25px;
}

.container__video h5 {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 25px;
}

.container__video--rise {
  font-size: 1.5rem;
  color: var(--color-azul);
  letter-spacing: 2px;
  margin-left: 25px;
}

.container__video button.btn-outline-danger {
  margin-top: 40px;
  font-size: 1.5rem;
}

.container__video--warning {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 10px;
  color: var(--color-text);
}

.container__video .fa-circle {
  font-size: 1.5rem;
  color: green;
  padding-top: 5px;
}

.container__video span {
  font-size: 1.8rem;
  text-transform: uppercase;
}

.numberPalet {
  color: var(--color-rojo);
  margin-left: 10px;
}

.border__Palet--title {
  border-bottom: 1px solid var(--color-disabled);
}

.container__video table {
  margin-top: 5px;
}

.container__video table td {
  font-size: 1.2rem;
}

.nav-tabs .nav-link.active {
  border-color: transparent;
  border: 0;
}

.container__information .nav-tabs .nav-link,
.container__information .nav-tabs .nav-link.active {
  color: black !important;
  font-size: 1.6rem;
  font-weight: normal;
  letter-spacing: 3px;
}

.container__information .nav-tabs .nav-link:hover,
.container__information .nav-tabs .nav-link.active:hover {
  text-decoration: none;
  background-color: initial;
}

.container__information .nav-tabs .nav-item.active {
  background-color: initial;
  border: 0;
}

.container__information .nav-tabs .nav-item {
  background-color: var(--color-fondo-gris);
  border-bottom: 2px solid var(--color-dorado);
  border-radius: 5px;
}

.container__information .nav-tabs .nav-item:last-child {
  border-left: 2px solid var(--color-dorado);
}

.videoStreaming {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.tableScroll {
  display: block;
  overflow-y: auto;
}
.header-now {
  align-items: center;
  width: 101%;
  border-radius: 3px;
  margin-top: -2px;
  margin-left: -2px;
  margin-right: 0px;
  text-transform: uppercase;
  color: var(--color-blanco);
  background-color: var(--color-rojo);
}
.header-now h3 {
  padding: 3px;
  letter-spacing: 0.5rem;
}
.img-now {
  margin-top: 15%;
}
.card__next:after {
  content: '';
  position: absolute;
  top: 100.3%;
  left: 75%;
  margin-left: -30%;
  width: 0;
  height: 0;
  border-top: solid 10px var(--color-rojo);
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}
.advice-streaming {
  border-radius: 5px;
  background-color: var(--color-azul);
  color: var(--color-blanco);
  font-size: 1.4rem;
}
.bids-scroll {
  max-height: 200px;
  overflow-y: scroll;
}
.win-trophy {
  font-size: 5rem;
  color: var(--color-dorado);
  padding-bottom: 10px;
}
.icon-youtube {
  font-size: 40rem;
}
.menu-stream-movil {
  display: none;
}
.exit-lotes {
  text-align: end;
}
.exit-lotes button:first-child {
  display: none;
}
.display-sections {
  display: none;
}
.displayed {
  color: var(--color-dorado);
  text-decoration: underline;
}
.no-border {
  border: none;
}
.grid-video {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.col-video1 {
  grid-column: span 4;
}
.col-video2 {
  grid-column: span 4;
}
.col-offer1 {
  grid-column: span 8;
}
.col-offer2 {
  grid-column: span 8;
}
.col-offer3 {
  grid-column: span 8;
}
.col-offer4 {
  grid-column: span 8;
}

#toastTryingConnect {
  visibility: hidden;
  min-width: 250px;
  background-color: var(--color-blanco);
  color: var(--color-text);
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.bar-width {
  height: 10px;
}
.toast-error {
  width: 100%;
}

#toastTryingConnect.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
#toastConnectionStablished {
  visibility: hidden;
  min-width: 250px;
  background-color: var(--color-blanco);
  color: var(--color-text);
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

#toastConnectionStablished.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
#toastStartConnect {
  visibility: hidden;
  min-width: 250px;
  background-color: var(--color-blanco);
  color: var(--color-text);
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

#toastStartConnect.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.icons_streaming {
  margin-top: -3px;
  width: 15px;
  height: 12px;
  padding-bottom: 3px;
}
.icons_streaming--border {
  border-bottom: 1px solid var(--color-dorado);
}
.text_icons {
  font-size: 0.9rem;
  padding-bottom: 4px;
}
.margin_information {
  margin-top: 0;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 50%;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 50%;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
.desc-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
h4 .far {
  font-size: 2rem;
}
@media (max-width: 1400px) {
  .container__Streaming {
    margin-top: 12%;
  }
  .container__streaming--carousel {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: auto;
  }
  .container__streaming--carousel__item_now {
    margin-right: 50px;
  }
  .btn-confirm {
    margin-right: 20px;
  }
}
@media (max-width: 1200px) {
  .container__Streaming {
    margin-top: 13%;
  }
  .btn-lot {
    margin-right: 5px;
  }
  .card__carousel {
    width: 105px;
    height: 137px;
  }
  .card__next {
    width: 321px;
    height: 137px;
  }
  .container__streaming--carousel__item {
    width: 145px;
    /*margin-left: -50px;*/
    margin-right: 0;
  }

  .container__streaming--carousel__item_now {
    margin-right: 235px;
    width: 145px;
  }
  .header-now {
    width: 321px;
    height: 137px;
  }
  .icon-youtube {
    font-size: 30rem;
  }
  .btn-confirm {
    font-size: 1rem;
    width: 88%;
    margin-right: 5px;
  }
}
@media (max-width: 1000px) {
  .btn-confirm {
    width: 80%;
  }
  .bids-scroll {
    max-height: 290px;
  }
}
@media (max-width: 976px) {
  .container__Streaming {
    margin-top: 15%;
  }
  .col-offer1 h3 {
    font-size: 2rem;
  }
  .container__video--offer {
    font-size: 2rem;
  }
  .exit-lotes button {
    font-size: 1.2rem;
  }
  .icon-youtube {
    font-size: 20rem;
  }
  .btn-confirm {
    margin-right: 15px;
    width: 90%;
  }
  .bids-scroll {
    max-height: 290px;
  }
}
@media (max-width: 752px) {
  .container__Streaming {
    margin-top: 18%;
  }
  .col-offer1 h3 {
    font-size: 2rem;
  }
  .exit-lotes button {
    font-size: 1rem;
  }
  .icon-youtube {
    font-size: 30rem;
  }
  .header-now h3 {
    font-size: 1rem;
  }
  .btn-confirm {
    width: 70%;
  }
  .margin_information {
    margin-top: 20px;
  }
  .bids-scroll {
    max-height: 193px;
  }
}
@media (max-width: 577px) {
  .container__Streaming {
    margin-top: 22%;
  }
  .container__Streaming--title {
    font-size: 1.5rem;
  }
  .menu-stream-movil {
    display: flex;
  }
  .container__Streaming--subtitle {
    font-size: 1.2rem;
  }
  .menu-stream-movil h5 {
    font-size: 1.1rem;
  }
  .btn-lot > span {
    display: none;
  }
  .btn-lot {
    margin-right: 0;
    width: 70%;
  }
  .col-video2 div {
    margin-top: -10px;
  }
  .container__video--warning {
    margin-top: 28px;
  }
  .container__streaming--carousel {
    border: none;
  }
  .grid-video {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }
  .col-video1 {
    grid-column: span 8;
  }
  .col-video2 {
    grid-column: span 8;
  }
  .col-offer1 {
    grid-column: span 5;
    text-align: center;
  }
  .col-offer1 h3 {
    font-size: 2rem;
  }
  .col-offer2 {
    margin: 6px 0 0 -30px;
    grid-column: span 3;
    text-align: left;
  }
  .col-offer3 {
    margin-top: 5px;
    grid-column: span 5;
    text-align: right;
  }
  .col-offer4 {
    margin: 0 0 0 -10px;
    grid-column: span 3;
  }
  .exit-lotes button {
    font-size: 1.3rem;
    width: 100%;
    text-align: center;
  }
  .exit-lotes button:first-child {
    display: initial;
  }
  .advice-streaming {
    margin: 0 1px 0 1px;
    text-align: center;
    padding-top: 10px;
  }

  .card__carousel {
    width: 145px;
    height: 221px;
  }
  .card__next {
    width: 145px;
    height: 221px;
  }
  .container__streaming--carousel__item {
    width: 145px;
    /*margin-left: -12px;*/
    margin-right: 20px;
  }

  .container__streaming--carousel__item_now {
    margin-right: 20px;
    width: 145px;
  }
  .header-now {
    width: 145px;
    height: 32px;
  }
  .container-titles {
    margin-top: -23px;
  }
  .card__next--title {
    font-size: 0.625rem;
  }
  .bids-scroll {
    max-height: 195px;
  }
}
@media (max-width: 400px) {
  .container__Streaming {
    margin-top: 30%;
  }
  .container__Streaming--title {
    font-size: 1.4rem;
  }
  .container__Streaming--subtitle {
    font-size: 1rem;
  }
  .btn-lot > span {
    display: none;
  }
  .btn-lot {
    width: 70%;
  }
  .col-video2 div {
    margin-top: -10px;
  }
  .container__video--warning {
    margin-top: 28px;
  }
  .grid-video {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }
  .col-video1 {
    grid-column: span 8;
  }
  .col-video2 {
    grid-column: span 8;
  }
  .col-offer1 {
    grid-column: span 5;
    text-align: center;
  }
  .col-offer1 h3 {
    font-size: 1.4rem;
  }
  .col-offer2 label {
    margin-top: 3px;
    font-size: 1.8rem;
  }
  .col-offer2 {
    margin: 6px 0 0 -28px;
    grid-column: span 3;
    text-align: left;
  }
  .col-offer3 {
    margin-top: 9px;
    grid-column: span 6;
    text-align: center;
  }
  .col-offer3 h5 {
    font-size: 1rem;
  }
  .col-offer4 {
    grid-column: span 2;
  }
  .col-offer4 label {
    margin: 0;
    text-align: left;
  }
  .exit-lotes button {
    font-size: 1rem;
    width: 105%;
    text-align: center;
  }
  .exit-lotes button:first-child {
    display: initial;
  }
  .menu-bottom {
    padding-top: 10px;
    box-shadow: 0 -5px 5px -5px #333;
  }
  .menu-stream-movil h5 {
    font-size: 1rem;
  }
  .card__carousel {
    width: 125px;
    height: 221px;
  }
  .card__next {
    width: 145px;
    height: 221px;
  }
  .container__streaming--carousel__item {
    width: 145px;
    /*margin-left: -34px;*/
  }

  .container__streaming--carousel__item_now {
    width: 145px;
    margin-right: 38px;
  }
  .icon-youtube {
    font-size: 25rem;
  }
  .bids-scroll {
    max-height: 200px;
  }
}
