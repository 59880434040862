.container {
  margin-top: 15px;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: var(--color-azul);
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: var(--color-azul);
  left: 0 !important;
  width: 100% !important;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: var(--color-azul);
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 22%;
  outline: none;
  max-width: 417px;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

.thumb::-webkit-slider-thumb {
  background-color: var(--color-dorado);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px var(--color-text);
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.thumb::-moz-range-thumb {
  background-color: var(--color-dorado);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px var(--color-text);
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.price-container {
  display: grid;
  grid-template-columns: 45% 10% 45%;
}

.price-box {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--color-text);
  border: 2px solid var(--color-dorado);
  padding: 10px;
  text-align: center;
}



@media(max-width: 1090px) {
  .price-container {
    display: flex;
  }
}

@media(max-width: 767px) {
  .thumb {
    width: 90%;
  }

  .price-container {
    display: grid;
  }
}

@media(max-width: 501px) {
  .thumb {
    width: 85%;
  }
}