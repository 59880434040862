.container-auctions .card:hover,
.container-auctionsHighlight .card:hover,
.container-catalog .card:hover {
  transform: scale(1.05);
  z-index: 1000;
}

.slidein {
  animation-duration: 1.5s;
  animation-name: slidein;
  animation-play-state: running;
}

@keyframes slidein {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);

    opacity: 1;
  }
}
