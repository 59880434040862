.container__VerifyUser {
  margin-top: 15%;
  margin-bottom: 15%;
}

.container__VerifyUser label {
  font-size: 3rem;
  color: var(--color-dorado);
}

.container__VerifyUser i {
  font-size: 3.5rem;
  margin-left: 10px;
  margin-right: 10px;
  color: var(--color-dorado);
}

.container__VerifyUser-content {
  border-style: outset;
}

.container__VerifyUser.hidden {
  visibility: hidden;
}

@media (max-width: 890px) {
  .container__VerifyUser {
    margin-top: 20%;
  }
}

@media (max-width: 500px) {
  .container__VerifyUser {
    margin-top: 25%;
  }

  .container__VerifyUser label {
    font-size: 2.5rem;
  }

  .container__VerifyUser i {
    font-size: 3rem;
  }
}

@media (max-width: 400px) {
  .container__VerifyUser {
    margin-top: 30%;
  }

  .container__VerifyUser label {
    font-size: 2rem;
  }

  .container__VerifyUser i {
    font-size: 2.5rem;
  }
}
