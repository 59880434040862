.container-contact {
  width: 90%;
  margin-top: 10%;
  margin-bottom: 10%;
}
.title-contact {
  margin-bottom: 43px;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 6px;
  color: var(--color-text);
}
.form-section {
  margin-left: 1%;
  margin-right: 1%;
}
.holder-border {
  background-color: var(--color-blanco);
}
.btn-send {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--color-link);
}
.head-comments {
  margin: 0 0 23px 20px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: var(--color-text);
}
.message-contact {
  margin-top: -3px;
}
.form-contact {
  margin-top: 5px;
  border-radius: 3px;
  border: solid 1px var(--color-azul);
}
.form-inputs-space {
  margin-bottom: 8px;
}
.align-terms {
  margin-left: 60px;
}
.terms-conditions {
  color: var(--color-turquesa);
}
.terms-conditions:hover {
  color: var(--color-link);
}
.contact-us {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: normal;
  text-align: justify;
  font-weight: bold;
  color: var(--color-text);
  margin-top: 15px;
  margin-left: 90px;
}
.follow-us {
  max-width: 550px;
  width: 80%;
  margin-left: 10%;
  font-weight: bold;
  padding-top: 20px;
  color: var(--color-dorado);
  border-top: 2px solid var(--color-dorado);
}
.stayed {
  margin-left: 10%;
  color: var(--color-text);
  font-weight: bold;
  font-size: 1.6rem;
}
.details-contact {
  width: 80%;
  max-width: 550px;
  margin-left: 10%;
  padding: 35px 0px 46px 40px;
  border-radius: 5px;
  background-color: var(--color-fondo-gris);
}
.icons-contact {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}
.acution-social {
  padding-top: 5px;
  color: var(--color-text);
}
.phone-contact {
  font-size: 1.5rem;
  color: var(--color-text);
}
.phone-contact:hover {
  text-decoration: underline;
  color: var(--color-text);
}
.email-contact {
  color: var(--color-turquesa);
}
.email-contact:hover {
  text-decoration: underline;
}
.icons-align {
  padding-right: 5px;
  color: var(--color-dorado);
}
.facebook-icon {
  margin-top: -5px;
  margin-right: 10px;
  font-size: 2.8rem;
  width: 27px;
  height: 26px;
  padding-right: 5px;
  color: var(--color-facebook);
}
.twitter-icon {
  margin-top: -5px;
  margin-right: 10px;
  font-size: 2.8rem;
  width: 27px;
  height: 26px;
  padding-right: 5px;
  color: var(--color-twitter);
}

.container-icon-social {
  margin-left: 10%;
}

.container-captcha {
  text-align: center;
}

.container-captcha > div > div > div > div > iframe {
  width: auto !important;
}

.icons-contact label {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .details-contact {
  padding: 35px 3px 46px 10px;
  }
}
@media (max-width: 1025px) {
  .container-contact {
    margin-top: 15%;
    margin-bottom: 15%;
  }

  .head-comments {
    font-size: 1.5rem;
  }
  .details-contact {
    width: 90%;
  }
  .stayed {
    font-size: 1.2rem;
  }
}

@media (max-width: 769px) {
  .container-contact {
    margin-top: 18%;
    margin-bottom: 4%;
  }

  .head-comments {
    font-size: 1.4rem;
  }
  .btn-send {
    font-size: 1.3rem;
  }
  .contact-us {
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .details-contact {
    margin: 0 auto;
    padding: 35px 0px 46px 40px;
  }
  .stayed {
    margin-left: 0;
    text-align: center;
  }
  .container-icon-social {
    margin-left: 0;
    text-align: center;
  }

  .container-icon-social > .icons-contact {
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .container-contact {
    margin-top: 25%;
    margin-bottom: 6%;
  }
  .align-terms {
    margin-left: 75px;
  }
  .container-captcha {
    text-align: left;
  }
  .container-captcha > div > div > div > div > iframe {
    width: 90% !important;
  }
}

@media (max-width: 500px) {
  .align-terms {
    margin-left: 50px;
  }
}

@media (max-width: 450px) {
  .align-terms {
    margin-left: 30px;
  }
}

@media (max-width: 420px) {
  .container-contact {
    margin-top: 30%;
  }
  .phone-contact b{
    padding-right: 110px;
  }
  .align-terms {
    margin-left: 0;
    font-size: 0.9rem;
  }
}

@media (min-width: 1780px) {
  .container-contact {
    max-width: 1587px;
  }
}
