/* width */
/*::-webkit-scrollbar {
  width: 1rem;
}*/

/* Track */
/*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1rem #d0d0d0;
  border-radius: 1rem;
}*/

/* Handle */
/*::-webkit-scrollbar-thumb {
    background: var(--color-dorado); 
    border-radius: 1rem;
  }*/

/* Handle on hover */
/*::-webkit-scrollbar-thumb:hover {
    background: var(--color-dorado); 
  }*/

.title-cart {
  font-size: 2rem;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

.cart-container {
  max-height: 60rem;
  width: 40rem;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: #f2f2f2;
}

.cart-img-center {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.cart-img {
  width: 20rem;
  height: 15rem;
}

.icon-danger {
  color: var(--color-rojo);
  font-size: 2rem;
  cursor: pointer;
}

.cart-text {
  font-size: 1.5rem;
}

.cart-icon {
  color: var(--color-azul);
  font-size: 2.5rem;
}

.cart-canvas {
  padding-top: 12rem;
  width: 50rem;
  overflow-y: auto;
}

.title-subtotal {
  color: var(--color-azul);
}

.cart-close {
  font-size: 1.5rem;
}

.container__cart .btn {
  font-size: 1.3rem;
}

.container__cart .text-destkop {
  display: block;
}

.container__cart .text-mobile {
  display: none;
}

@media (max-width: 576px) {
  .cart-canvas {
    padding-top: 9rem;
    width: 40rem;
  }
  .container__cart .card {
    width: 350px;
  }
  .container__cart .col-full {
    width: 100%;
  }

  .title-cart {
    font-size: 1.5rem;
  }

  .cart-icon {
    font-size: 2rem;
  }

  .container__cart .card-left {
    padding-left: 0;
  }

  .container__cart .cart-img {
    margin-top: 3%;
  }

  .cart-text {
    font-size: 1.3rem;
    margin-left: 15%;
  }

  .container__cart .btn {
    font-size: 1.3rem;
  }

  .container__cart .text-destkop {
    display: none;
  }

  .container__cart .text-mobile {
    display: block;
  }
}

@media (max-width: 450px) {
  .cart-canvas {
    width: 30rem;
  }

  .container__cart .card {
    width: 260px;
  }
}
